<template>
  <div class="ExamPaperAnswer">
    <ExamDetailsHeader
      ref="ExamDetailsHeader"
      router-url="examlist"
      :query="{ examId: $route.query.examId }"
      :name="examInformation.name + ' / '"
    >
    </ExamDetailsHeader>
    <!-- 科目列表 -->
    <div class="subject-box">
      <div>
        <template v-for="(v, i) in paperList">
          <div
            v-if="getTabShow(2, v.subjectId)"
            :key="i"
            class="subject-list"
            :class="{ on: indexPaperId == v.paperId }"
            :disabled="answerAllLoading"
            @click="subjectClick(v)"
          >
            {{ v.subjectName }}
          </div>
        </template>
      </div>
    </div>
    <!-- 答案设置 -->
    <div class="answer-box">
      <div v-if="!percentage" v-loading="questionLoading">
        <div class="answer-box-header">
          <div class="answer-box-header-cnt">
            <div>
              答案设置：
              <el-button type="primary" plain @click="answerAllType = true">
                显示快捷录入
              </el-button>
              <el-button type="primary" plain @click="answerAllType = false">
                收起快捷录入
              </el-button>
            </div>
            <div>
              <el-checkbox v-model="showABBox">显示AB卷</el-checkbox>
              <el-button
                class="answer-btn"
                type="danger"
                plain
                @click="showClearAnswer(0)"
              >
                清除全部答案
              </el-button>
            </div>
          </div>

          <el-input
            v-if="answerAllType"
            v-model="answerAll"
            class="answer-all"
            placeholder="请输入内容"
          ></el-input>
          <p class="tips">
            请在上面输入框中输入答案，形如：ABCDABCD，表示1题答案A，2题答案B，3题答案C，以此类推。注：只支持单选题，不支持多选，不支持B卷快捷录入，不支持特殊小题（快捷录入会自动跳过特殊小题）。
          </p>
          <div>
            <el-button
              type="primary"
              plain
              :loading="answerAllLoading"
              @click="setexamquestionswerquick(1)"
            >
              确定录入
              <template v-if="showABBox">A</template></el-button
            >
            <el-button
              v-if="showABBox"
              type="primary"
              plain
              :loading="answerAllLoading"
              @click="setexamquestionswerquick(2)"
              >确定录入B</el-button
            >
          </div>

          <div class="question-tips">
            <p>
              1.如果答案是多选，输入如“ACD”；如果选A也对，选B也对，选AB也对，输入“A,B,AB”；如果全给分，输入“?”。
            </p>
            <p>
              2.如果选对即得分，[ACD]选A，C，D任何一项即得该题的 (1/选项数)
              分。如果学生所选答案超过正确答案数量，超过正确答案数量为0分。
            </p>
            <p>
              3.如果选错即零分，(ACD)选A，C，D任何一项即得该题的 (1/选项数)
              分,(ACD;2)选A，C，D任何一项即得2分
              。全部选对的得满分，部分选对的得部分分，有选错的得0分
            </p>
            <p>
              4.请不要在识别答题卡的过程中提交标准答案，否则会导致部分学生的成绩有误！
            </p>
          </div>
          <div>
            <el-button type="primary" plain @click="showSpecial('answer')"
              >特殊小题录入</el-button
            >
            <div></div>
          </div>
        </div>
        <!-- 客观题 -->
        <div class="question-box">
          <div class="question-header" :class="{ 'ab-title': showABBox }">
            <div>客观题<span v-if="showABBox">A</span></div>
            <el-button
              type="text"
              class="clear-btn"
              @click="showClearAnswer(1)"
            >
              清除客观题答案
            </el-button>
          </div>
          <div class="question-list-box">
            <template v-for="(v, i) in paperQuestionList">
              <div v-if="!v.type" :key="i" class="question-list">
                <p class="question-list-title">
                  {{ v.examQuestionOrder }}.{{ v.optionCount | setOption(v) }}
                </p>
                <!-- width: 144px; -->
                <el-input
                  v-model="v.answer"
                  :class="{ isSpecialWidth: v.isSpecial == 1 }"
                  placeholder=""
                  :disabled="submitLoading"
                  @focus="showIndexModel(v, 'answer')"
                ></el-input>
                <el-popover
                  v-if="v.isSpecial == 1"
                  placement="top-start"
                  title="提示"
                  width="400"
                  trigger="hover"
                  content="该题为特殊小题，每个特殊选项以“；”分割，分数与选项之间用“，”分割，点击输入框可查看详情。"
                >
                  <el-button slot="reference" type="primary" class="hover-btn"
                    >?</el-button
                  >
                </el-popover>
              </div>
            </template>
          </div>
        </div>
        <div v-if="showABBox">
          <el-button type="primary" plain @click="showSpecial('answerB')"
            >特殊小题录入</el-button
          >
          <div></div>
        </div>
        <div v-if="showABBox" class="question-box">
          <div class="question-header ab-title">客观题<span>B</span></div>
          <div class="question-list-box">
            <template v-for="(v, i) in paperQuestionList">
              <div v-if="!v.type" :key="i" class="question-list">
                <p class="question-list-title">
                  {{ v.examQuestionOrder }}.{{ v.optionCount | setOption(v) }}
                </p>
                <el-input
                  v-model="v.answerB"
                  placeholder=""
                  @focus="showIndexModel(v, 'answerB')"
                ></el-input>
                <el-popover
                  v-if="v.isSpecialB == 1"
                  placement="top-start"
                  title="提示"
                  width="400"
                  trigger="hover"
                  content="该题为特殊小题，每个特殊选项以“；”分割，分数与选项之间用“，”分割，点击输入框可查看详情。"
                >
                  <el-button slot="reference" type="primary" class="hover-btn"
                    >?</el-button
                  >
                </el-popover>
              </div>
            </template>
          </div>
        </div>
        <div class="submit-btn-box">
          <el-button
            type="primary"
            plain
            :loading="submitLoading"
            @click="getQuestionSort"
            >提交</el-button
          >
        </div>
      </div>
      <div v-if="percentage">
        <el-progress
          class="el-progress-animation"
          :percentage="progressValue"
        ></el-progress>
        <div class="percentage-text">{{ progressText }}</div>
      </div>

      <!-- 主观题 -->
      <div class="question-box">
        <div class="question-header">主观题</div>
        <div class="question-order" style="display: flex">
          <div
            v-for="(v, i) in paperQuestionList.filter((i) => i.type)"
            :key="i"
            class="question-list"
          >
            {{ v.examQuestionOrder
            }}<template
              v-if="i + 1 != paperQuestionList.filter((i) => !i.type).length"
            >
              {{ v.answerUploadStatus | getStatus() }}
            </template>
          </div>
        </div>
      </div>
      <!-- 上传主观题答案 -->
      <div class="question-box">
        <div class="question-header">
          <div>
            Word文件上传答案
            <el-button type="text" icon="el-icon-download" @click="downFile">
              下载主观题答案模板
            </el-button>
          </div>

          <el-button type="text" class="clear-btn" @click="showClearAnswer(2)">
            清除主观题答案
          </el-button>
        </div>
        <el-upload
          class="upload-demo"
          drag
          :action="examanswerModifyURL"
          :headers="uploadHeaders"
          accept=".docx,.doc"
          :show-file-list="false"
          :before-upload="handleImportBefore"
          :on-success="batchModifyOnSuccess"
          :on-error="batchModifyOnError"
          :on-progress="batchModifyOnProgress"
          :data="wordData"
          :limit="1"
        >
          <!-- <i class="el-icon-upload"></i> -->
          <img
            class="up-img"
            src="@/assets/newExam/sheet/icon_upload.png"
            alt=""
          />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip" class="el-upload__tip">
            只能上传word文件,请保持'题号'与上方主观题列表一致
          </div>
        </el-upload>
      </div>
      <!-- 主观题答案 -->
      <div
        v-if="paperQuestionList.filter((i) => i.type && i.answer).length > 0"
        class="question-box"
      >
        <div class="question-header">主观题答案</div>
        <div class="question-order">
          <template v-for="(v, i) in paperQuestionList">
            <div v-if="v.type && v.answer" :key="i" class="question-list">
              <div class="question-list-item">
                <span class="question-list-item-title">【题号】</span
                >{{ v.examQuestionOrder }}
              </div>
              <div class="question-list-item">
                <span class="question-list-item-title">【答案】</span
                ><span v-html="v.answer"></span>
              </div>
              <!-- <div class="question-list-item">
                【解答】 <span v-html="v.analysis"></span>
              </div> -->
            </div>
          </template>
        </div>
      </div>
      <el-dialog
        title="给分方式设置"
        :visible.sync="dialogVisible"
        width="667px"
      >
        <div>
          <div class="question-score-item-box">
            <span class="question-score-title">选项小题：</span>
            <el-select
              v-model="scoreNumber"
              placeholder="请选择"
              @change="checkQuestion"
            >
              <template v-for="item in paperQuestionList">
                <el-option
                  v-if="!item.type"
                  :key="item.id"
                  :label="item.examQuestionOrder"
                  :value="item.id"
                >
                </el-option>
              </template>
            </el-select>
          </div>
          <div class="question-score-item-box">
            <!-- -->
            <div
              v-for="(v, i) in specialList"
              :key="i"
              class="question-score-item"
            >
              <span class="question-score-title">选项：</span>
              <el-input v-model="v.option"></el-input>
              <span>得分：</span>
              <el-input
                v-model="v.score"
                oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,2})?/g) ?? [""])[0]'
                @blur="v.score = $event.target.value"
              ></el-input>
              <el-button type="text" @click="delSpecial(i)">删除</el-button>
            </div>
            <div class="add-btn-box">
              <el-button type="text" icon="el-icon-plus" @click="addspecial()">
                继续添加
              </el-button>
            </div>
          </div>
          <div class="question-score-tips">
            <p><span>!</span>提示</p>
            <div>
              如选A得1分，选B得1分，选AB得2分。则输入“选项：A，得分：1”，“选项：B，得分：1”，“选项：AB，得分：2”。
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            type="primary"
            :loading="btnLoading"
            @click="setexampaperanswerspecial"
          >
            确 定
          </el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 客观题顺序不一致对比 -->
    <el-dialog
      title="校验客观题顺序"
      :visible.sync="objectiveType"
      width="548px"
      :show-close="false"
    >
      <div>当前客观题排序：</div>
      <div style="line-height: 21px" v-html="oldObjArr"></div>
      <div>检测排序：</div>
      <div style="line-height: 21px">
        {{ newObjArr }}
      </div>
      <div>如果当前客观题排序正确，请点击 “忽略”</div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="objectiveType = false">取 消</el-button>
        <el-button type="primary" @click="submitChoice"> 忽 略 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const specialObj = {
  option: "",
  score: "",
};

import { getObj } from "@/core/api/exam/exam";
import {
  getexampaperanswer,
  updatebatchpolling,
  updatebatch,
  clearPapersheetdetail,
} from "@/core/api/exam/examPaperAnswer";
import { fileDownloadByUrl } from "@/core/util/util";
import ExamDetailsHeader from "@/components/examinationHeader";
import { getStore } from "@/core/util/store";
import { radixSort } from "@/views/sheet/js/sort_question";
import { optionItem } from "@/core/util/constdata";
export default {
  name: "ExamPaperAnswer",
  components: {
    ExamDetailsHeader,
  },
  filters: {
    setOption(val, item) {
      let optionArr = JSON.parse(JSON.stringify(optionItem));
      // let optionArr = optionItem;
      if (item.selectType == 3) {
        optionArr = ["T", "F"];
      }
      optionArr.splice(val);
      return optionArr.join(" ");
    },
    getStatus(val) {
      let str = "";
      // 0-未上传，1-已上传，2-转换中，3-转换失败
      // if (val == 0) {
      //   str = "未上传答案";
      // }
      if (val == 2) {
        str = "答案转换中";
      }
      if (val == 3) {
        str = "答案转换失败";
      }
      return str;
    },
  },
  data() {
    return {
      optionItem: optionItem,
      objectiveType: false,
      oldObjArr: "",
      newObjArr: "",
      // 特殊题
      specialObj: specialObj,
      specialList: [],
      // 给分方式
      dialogVisible: false,
      // 批量录入选择题答案
      answerAll: "",
      answerAllLoading: false,
      // 是否显示ab卷
      showABBox: false,
      //是否显示快捷录入
      answerAllType: false,
      //考试基本信息
      examInformation: {
        name: "",
      },
      indexPaperId: "",
      paperList: [],
      //所有试题列表
      paperQuestionList: [],
      // 上传答案 start
      examanswerModifyURL: "/exam/exampapersheetdetail/uploadexampaperanswer",
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      wordData: {
        examPaperId: "",
      },
      // 上传答案 end
      scoreNumber: "",
      btnLoading: false,
      //
      submitLoading: false,
      percentage: false,
      questionLoading: false,
      progressValue: 0,
      progressText: "",
    };
  },
  watch: {
    answerAll() {
      this.answerAll = this.answerAll.replace(/[^a-zA-Z]/, "");
    },
  },
  created() {
    this.getObj();
    this.indexPaperId = this.$route.query.paperId;
    this.getexampaperanswer();
    this.updatebatchpolling(1);
  },
  destroyed() {
    clearTimeout(this.newTime);
  },
  methods: {
    getTabShow(key, subjectId) {
      let type = false;
      let userInfo = getStore({ name: "userInfo" });
      if (
        this.examInformation.examWorkList &&
        this.examInformation.examWorkList.length > 0
      ) {
        this.examInformation.examWorkList.forEach((item) => {
          if (item.subjectType == key && item.subjectId == subjectId) {
            item.teacherList.map((index) => {
              if ((index.uesrId = userInfo.id)) {
                type = true;
              }
            });
          }
        });
      }
      if (userInfo.roleId != 5) {
        type = true;
      }
      return type;
    },
    // 获取题号顺序是否相同
    getQuestionSort() {
      let arr = [];
      // console.log(this.paperQuestionList);
      this.paperQuestionList.map((index) => {
        if (index.selectType == 1) arr.push(index.examQuestionOrder);
      });
      let radixSortArr = radixSort([...arr]);
      let htmlArr = [];
      radixSortArr.map((item, index) => {
        if (arr[index] == item) {
          htmlArr.push(`<span>${arr[index]}</span>`);
        } else {
          htmlArr.push(`<span style="color:red">${arr[index]}</span>`);
        }
      });
      this.oldObjArr = `<div>${htmlArr.join(",")}</div>`;
      this.newObjArr = radixSort([...arr]).join(",");
      if (arr.join(",") == this.newObjArr) {
        this.submitChoice();
      } else {
        this.objectiveType = true;
      }
    },
    updatebatchpolling(type) {
      let data = {
        examPaperId: this.indexPaperId,
      };
      this.questionLoading = true;
      updatebatchpolling(data)
        .then((res) => {
          this.questionLoading = false;
          // this.progressValue = 10;
          this.progressValue = Number(res.data.data.percentage);
          this.progressText = res.data.data.describe;
          if (type) {
            if (res.data.data.percentage != 100) {
              this.percentage = true;
              this.newTime = setTimeout(() => {
                this.updatebatchpolling();
              }, 1000);
            } else {
              this.percentage = false;
            }
          } else {
            if (res.data.data.percentage < 100) {
              this.percentage = true;
              this.newTime = setTimeout(() => {
                this.updatebatchpolling();
              }, 1000);
            } else {
              this.percentage = false;
              this.$confirm("答案设置成功！", "提示", {
                confirmButtonText: "确定",
                // cancelButtonText: "取消",
                type: "success",
              })
                .then(() => {
                  this.getexampaperanswer();
                })
                .catch(() => {});
            }
          }
        })
        .catch(() => {
          this.questionLoading = false;
        });
    },
    clearPapersheetdetail(data, instance, done) {
      clearPapersheetdetail(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "清除成功!",
          });
          this.getexampaperanswer();
          done();
          instance.confirmButtonLoading = false;
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },
    // 清除主观题答案
    showClearAnswer(type) {
      let str = "确认清除全部答案吗？";
      if (type == 1) {
        str = "确认清除客观题答案吗？";
      }
      if (type == 2) {
        str = "确认清除主观题答案吗？";
      }
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            let data = {
              type: type,
              examPaperId: this.indexPaperId,
            };
            this.clearPapersheetdetail(data, instance, done);
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    // 特殊题设置 start
    // 切换试题时
    checkQuestion() {
      // console.log(this.paperQuestionList);
      // console.log(this.scoreNumber);
      let indexQuestion = this.paperQuestionList.filter(
        (item) => item.id == this.scoreNumber
      );
      this.specialList = [];
      if (indexQuestion.isSpecial) {
        let data = Object.assign({}, indexQuestion);
        let array = data.answer.split(";");
        array.forEach((item) => {
          this.specialList.push({
            option: item.split(",")[0],
            score: item.split(",")[1],
          });
        });
      }
    },
    // showIndexModel
    // 显示当前特殊题内容
    showIndexModel(v, key) {
      if (v.isSpecialB < 1 && key == "answerB") {
        return;
      }
      if (v.isSpecial < 1 && key == "answer") {
        return;
      }
      this.indexAnswerKey = key;
      this.specialList = [];
      let data = Object.assign({}, v);
      let array = [];
      if (data[key]) {
        array = data[key].split(";");
        array.forEach((item) => {
          this.specialList.push({
            option: item.split(",")[0],
            score: item.split(",")[1],
          });
        });
      }

      this.scoreNumber = v.id;
      this.dialogVisible = true;
    },
    delSpecial(i) {
      this.specialList.splice(i, 1);
    },
    // 显示特殊题弹框
    showSpecial(key) {
      this.indexAnswerKey = key;
      this.specialList = [];
      this.scoreNumber = "";
      this.dialogVisible = true;
    },
    // 添加一行
    addspecial() {
      this.specialList.push(Object.assign({}, this.specialObj));
    },
    //保存特殊题
    setexampaperanswerspecial() {
      // scoreNumber
      // ifthis.paperQuestionList
      if (this.scoreNumber == "") {
        this.$message({
          message: "请选择试题",
          type: "warning",
        });
        return;
      }
      let data = this.paperQuestionList.filter(
        (i) => i.id == this.scoreNumber
      )[0];
      // 判断是否存在空值
      let isTrue = false;
      // 判断值是否存在大于小题情况
      let scoreTrue = false;
      let scoreArr = [];
      let array = [];
      let subArr = [];
      this.specialList.forEach((item, index) => {
        subArr.push(item.option + "," + item.score);
        if (item.option == "" || item.score == "") {
          array.push(index + 1);
          isTrue = true;
        }
        if (item.score > data.score) {
          scoreTrue = true;
          scoreArr.push(index + 1);
        }
      });
      // console.log(subArr);
      if (scoreTrue) {
        this.$message({
          message: `第${scoreArr.join(",")}项分数大于小题分数！`,
          type: "warning",
        });
        return;
      }
      if (isTrue) {
        this.$message({
          message: `第${array.join(",")}项值为空！`,
          type: "warning",
        });
        return;
      }

      if (this.specialList.length == 0) {
        if (this.indexAnswerKey == "answer") {
          data.isSpecial = 0;
        } else {
          data.isSpecialB = 0;
        }
        data[this.indexAnswerKey] = "";
      } else {
        if (this.indexAnswerKey == "answer") {
          data.isSpecial = 1;
        } else {
          data.isSpecialB = 1;
        }
        data[this.indexAnswerKey] = subArr.join(";");
      }
      // data.examPaperId = this.indexPaperId;
      // this.btnLoading = true;
      this.paperQuestionList.map((item) => {
        if (item.id == data.id) {
          Object.assign(item, data);
        }
      });
      // this.btnLoading = false;
      this.dialogVisible = false;
      // console.log(data);
      // setexampaperanswerspecial(data)
      //   .then(() => {
      //     this.$message({
      //       showClose: true,
      //       type: "success",
      //       message: "答案设置成功！",
      //       duration: 1000,
      //     });
      //     this.btnLoading = false;
      //     this.dialogVisible = false;
      //     this.getexampaperanswer();
      //   })
      //   .catch(() => {
      //     this.btnLoading = true;
      //   });
    },
    //特殊题设置 end
    setQuestionList(answerAll, type) {
      let index = 0;
      this.paperQuestionList.map((item) => {
        if (item.selectType == 1) {
          if (type == 1) {
            item.answer = answerAll[index];
            index++;
          }
          if (type == 2) {
            item.answerB = answerAll[index];
            index++;
          }
        }
      });
      this.$message({
        showClose: true,
        type: "success",
        message: "答案设置成功,请点击下方保存按钮！",
        duration: 2000,
      });
    },
    // 快速保存答案
    setexamquestionswerquick(type) {
      // console.log(type);
      // console.log(this.paperQuestionList);
      if (this.answerAll.length == 0) {
        this.$message.error("请先输入答案");
        return;
      }
      this.answerAll = this.answerAll.toUpperCase();
      // this.answerAll = this.answerAll.replace("/g ,，；", "");
      let answerAll = this.answerAll.split("");

      let questionList = this.paperQuestionList.filter(
        (item) => item.selectType == 1
      );
      if (questionList.length != answerAll.length) {
        const h = this.$createElement;
        this.$msgbox({
          title: "提示！",
          message: h("p", null, [
            h(
              "span",
              null,
              "当前输入答案数量与单选题数量不一致，是否继续保存？ "
            ),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.setQuestionList(answerAll, type);
          })
          .catch(() => {});
      } else {
        this.setQuestionList(answerAll, type);
      }
    },
    // 上传答案 start
    handleImportFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    handleImportBefore(file) {
      // .docx,.doc
      if (!(file.name.endsWith(".docx") || file.name.endsWith(".doc"))) {
        this.$message.error("只能上传 word 文件！");
        return false;
      }
      let is10M = file.size / 1024 / 1024 <= 10;
      if (!is10M) {
        this.$message.error("文件大小不能超过 10 MB！");
        return false;
      }
      const _vm = this;
      _vm.wordData.examPaperId = this.indexPaperId;
      // _vm.pdfData.参数1 = '值1';
      // this.fullscreenLoading = true;
      return true;
    },
    batchModifyOnSuccess(response, file, fileList) {
      // console.log(response);
      if (response.code == 0) {
        this.loading = false;
        this.handleImportFileRemove(fileList);
        setTimeout(() => {
          this.getexampaperanswer();
        }, 2000);
        this.$message({
          showClose: true,
          type: "success",
          message: "上传成功，请等待系统转换！",
          duration: 3000,
        });
      } else {
        this.loading = false;
        this.handleImportFileRemove(fileList);
        this.$message({
          showClose: true,
          type: "error",
          message: response.msg || "服务器内部错误!",
          duration: 3000,
        });
        // setTimeout(() => {
        //   this.getexampaperanswer();
        // }, 2000);
      }
    },
    batchModifyOnError(err, file, fileList) {
      this.loading = false;
      this.handleImportFileRemove(fileList);
    },
    batchModifyOnProgress() {
      this.batchAmendingVisible = false;
      this.loading = true;
    },
    // 上传答案  end
    downFile() {
      let str =
        "https://jzjx-resource.oss-cn-chengdu.aliyuncs.com/analy/template/%E4%B8%BB%E8%A7%82%E9%A2%98%E7%AD%94%E6%A1%88%E6%A8%A1%E6%9D%BF.docx";
      fileDownloadByUrl(str, "设置主观题答案模板");
    },
    // 科目点击
    subjectClick(v) {
      if (this.indexPaperId == v.paperId) {
        return;
      }
      this.answerAll = "";
      this.indexPaperId = v.paperId;
      this.$router.push({
        query: {
          paperId: v.paperId,
          examId: this.examInformation.examId,
        },
      });
      this.$nextTick(() => {
        this.$refs.ExamDetailsHeader.init(this.indexPaperId);
      });

      this.getexampaperanswer();
      this.updatebatchpolling(1);
      // this.handleClick();
    },
    checkSubject(item) {
      this.subjectClick(item);
    },
    goBack() {
      this.$router.push({
        name: "examlist",
        query: { examId: this.$route.query.examId },
      });
    },
    //获取考试基本信息
    getObj() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        this.paperList = res.data.data.paperList.map((item) => {
          item.label = item.subjectName;
          item.value = item.paperId;
          return item;
        });
        this.$nextTick(() => {
          this.$refs.ExamDetailsHeader.init(this.indexPaperId);
        });
      });
    },
    // 提交选择题submitChoice
    submitChoice() {
      this.objectiveType = false;
      // 过滤非选择题  特殊题
      let questionList = JSON.parse(
        JSON.stringify(this.paperQuestionList.filter((item) => !item.type))
      );
      if (!this.showABBox) {
        questionList = questionList.map((item) => {
          item.isSpecialB = 0;
          item.answerB = "";
          return item;
        });
      }
      // 给答案排个序
      questionList = questionList.map((item) => {
        if (item.answer) {
          item.answer = item.answer.replaceAll(" ", "");
          item.answer = item.answer.replaceAll(" ", "");
        }
        if (item.answerB) {
          item.answerB = item.answerB.replaceAll(" ", "");
          item.answerB = item.answerB.replaceAll(" ", "");
        }
        return item;
      });
      this.$confirm(
        " 请不要在识别答题卡的过程中提交标准答案，否则会导致部分学生的成绩有误！ ",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.submitLoading = true;
          updatebatch(questionList)
            .then(() => {
              this.submitLoading = false;
              this.updatebatchpolling();
            })
            .catch(() => {
              this.submitLoading = false;
            });
        })
        .catch(() => {
          // this.submitLoading = false;
        });
    },
    //获取考试基本信息
    getexampaperanswer() {
      let data = {
        examPaperId: this.indexPaperId,
      };
      // type 0其他类型 1 选择  2 多选
      getexampaperanswer(data).then((res) => {
        this.paperQuestionList = res.data.data;
        let answer = [];
        this.paperQuestionList.forEach((item) => {
          if (item.selectType == 1 && item.answer) {
            answer.push(item.answer);
          }
          if (item.answerB) {
            this.showABBox = true;
          }
        });
        if (this.showABBox) {
          answer = [];
        }
        if (answer.length > 0) {
          this.answerAll = answer.join("");
        }
        // console.log(answer);
        // console.log(res.data.data);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamPaperAnswer {
  padding: 24px 0 60px;
  .up-img {
    margin-top: 44px;
  }
  .question-score-tips {
    background: #fdf0ee;
    border: 1px dashed #ff7575;
    padding: 12px;
    margin-top: 24px;
    div {
      padding-left: 24px;
    }
    p {
      color: #f17e5e;
      display: flex;
      align-items: center;

      span {
        background-color: #f17e5e;
        margin-right: 8px;
        border-radius: 50%;
        display: block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0;
        color: #ffffff;
      }
    }
  }
  .question-score-item-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .add-btn-box {
      width: 100%;
      padding-left: 75px;
      margin-top: 20px;
    }
    .question-score-title {
      width: 77px;
      display: block;
      text-align: right;
    }
    .question-score-item {
      display: flex;
      margin-top: 12px;
      align-items: center;

      .el-input {
        width: 130px;
        margin-right: 8px;
      }
    }
  }
  .answer-box {
    padding: 24px;
    background-color: #ffffff;
    margin-top: 24px;
    .submit-btn-box {
      text-align: center;
    }
    .percentage-text {
      color: red;
      margin-top: 8px;
    }
    .question-box {
      margin-top: 24px;
      .question-list-item-title {
        flex-shrink: 0;
      }
      .question-order {
        // display: flex;
        padding: 18px;
        flex-wrap: wrap;
        .question-list {
          margin-right: 20px;

          &-item {
            display: flex;
            line-height: 1.8;
          }
        }
      }
      .upload-demo {
        margin-top: 24px;
      }
      .question-header.ab-title {
        position: relative;
        span {
          position: absolute;
          left: 50%;
          color: #333333;
          font-size: 16px;
        }
      }
      .question-header {
        background: #f2f5f8;
        border-radius: 2px;
        padding: 18px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        .clear-btn {
          color: #fe5f59;
        }
        span {
          font-size: 12px;
          color: #9b9b9b;
          cursor: pointer;
        }
        i {
          color: $primary-color;
        }
      }
      .question-list-box {
        padding: 18px;
        display: flex;
        flex-wrap: wrap;

        .hover-btn {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          overflow: hidden;
          padding: 0;
          text-align: center;
          line-height: 16px;
          margin-left: 20px;
          font-size: 12px;
          letter-spacing: 0;
        }
        .question-list {
          width: 280px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          .question-list-title {
            width: 85px;
            flex-shrink: 0;
            margin-bottom: 0;
          }
          .el-input {
            width: 100px;
            margin-left: 12px;
          }
          .isSpecialWidth {
            width: 144px;
          }
        }
      }

      // border: 1px solid #D8D8D8;
    }
    .answer-box-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .answer-box-header-cnt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .answer-btn {
          margin-left: 12px;
        }
      }
      .question-tips {
        margin: 10px 0;
        width: 100%;
        background: #f2f8fe;
        border: 1px dashed #2474ed;
        padding: 12px;
        color: #737677;
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .tips {
        color: #9b9b9b;
        margin: 10px 0;
        width: 100%;
      }
      .answer-all {
        // &:focus {
        //   ::v-deep input {
        //     border-color: #e7e7e7;
        //   }
        // }
        ::v-deep input {
          background-color: #e7e7e7;
          color: #9b9b9b;
          &:focus {
            border-color: #e7e7e7;
          }
          &::placeholder {
            color: #9b9b9b;
          }
        }
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  .subject-box {
    padding: 24px;
    background-color: #ffffff;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .subject-list {
      margin-right: 40px;
      color: #5b625e;
      position: relative;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transition: 0.2s;
        bottom: -5px;
        width: 18px;
        height: 2px;
        background: #ffffff;
      }
      &:hover {
        color: $primary-color;
        &::after {
          background: $primary-color;
          transition: 0.2s;
        }
      }
    }
    .on {
      color: $primary-color;
      position: relative;
      &::after {
        background: $primary-color;
      }
    }
    div {
      display: flex;
    }
  }
  .header-box {
    background-color: #ffffff;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .title {
      font-size: 16px;
      font-weight: bold;
      // margin-bottom: 24px;
      width: 100%;
      cursor: pointer;
      .el-icon-arrow-left {
      }
    }
  }
}
</style>
