import request from "@/core/services/axios";

export function getexampaperanswer(query) {
  return request({
    url: "/exam/exampapersheetdetail/getexampaperanswer",
    method: "get",
    params: query,
  });
}

// 获取答案设置进度
export function updatebatchpolling(query) {
  return request({
    url: "/exam/exampapersheetdetail/updatebatchpolling",
    method: "get",
    params: query,
  });
}

export function addObj(obj) {
  return request({
    url: "/exam/exampaper",
    method: "post",
    data: obj,
  });
}

// 清除试题答案
export function clearPapersheetdetail(obj) {
  return request({
    url: "/exam/exampapersheetdetail/clear",
    method: "get",
    params: obj,
  });
}

export function setexamquestionswerquick(obj) {
  return request({
    url: "/exam/exampapersheetdetail/setexamquestionswerquick",
    method: "post",
    data: obj,
  });
}
// /exam/exampapersheetdetail/setexampaperanswerspecial
export function getObj(id) {
  return request({
    url: "/exam/exampaper/" + id,
    method: "get",
  });
}

// 保存特殊小题
export function setexampaperanswerspecial(data) {
  return request({
    url: "/exam/exampapersheetdetail/setexampaperanswerspecial",
    method: "post",
    data: data,
  });
}

// 批量修改答案
export function updatebatch(data) {
  return request({
    url: " /exam/exampapersheetdetail/updatebatch",
    method: "put",
    data: data,
  });
}
